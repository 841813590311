import 'fitify-ui-landing/src/theme/Fonts.css'
import 'react-loading-skeleton/dist/skeleton.css'

import { getAnalytics, setUserProperties } from 'firebase/analytics'
import { getApp, initializeApp } from 'firebase/app'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { getPerformance } from 'firebase/performance'
import {
  colors as themeColors,
  FitifyThemeProvider,
  RemoteConfig,
  useRemoteConfig,
  useSmartlook,
  useAmplitude,
} from 'fitify-ui'
import { ThemeVariants } from 'fitify-ui/src/theme'
import { logEvent } from 'fitify-ui/src/utils/analytics'
import { ELEMENT_IDS } from 'fitify-ui-landing/src/@types/constants'
import { textVariants } from 'fitify-ui-landing/src/theme/textVariants/textVariants'
import { toBoolean } from 'fitify-utils/src/properties'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { appWithTranslation, useTranslation } from 'next-i18next'
import { DefaultSeo } from 'next-seo'
import { useEffect, useMemo, useState } from 'react'
import TagManager from 'react-gtm-module'
import { SkeletonTheme } from 'react-loading-skeleton'

import { GlobalStyleProxy } from '@/theme/globalStyle'

import SEO from '../../next-seo.config'

declare global {
  interface Window {
    // Used in VWO
    updateOptimizeConfig: (optimizeConfig: Record<string, unknown>) => void
  }
}

const app = initializeApp({
  apiKey: process.env.NEXT_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
})

if (process.env.NEXT_PUBLIC_FUNCTIONS_EMULATOR_PORT) {
  connectFunctionsEmulator(
    getFunctions(getApp()),
    'localhost',
    parseInt(process.env.NEXT_PUBLIC_FUNCTIONS_EMULATOR_PORT)
  )
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const remoteConfig = useRemoteConfig({
    app: 'landing',
  })
  const [optimizeConfig, setOptimizeConfig] = useState<RemoteConfig>()

  const config: RemoteConfig = useMemo(() => {
    return { ...remoteConfig, ...optimizeConfig }
  }, [remoteConfig, optimizeConfig])

  useEffect(() => {
    window.updateOptimizeConfig = (newOptimizeConfig) => {
      const updatedOptimizeConfig = {
        ...optimizeConfig,
        ...newOptimizeConfig,
      }
      setOptimizeConfig(updatedOptimizeConfig)
    }
  }, [optimizeConfig])

  // Handle smartlook
  const isSmartlookEnabled = toBoolean(config?.['smartlook_enabled'])
  useSmartlook(isSmartlookEnabled)

  const amplitudeApiKey = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY
  useAmplitude(amplitudeApiKey)

  // Solved canonicalUrl by https://rishimohan.me/blog/nextjs-canonical-tag
  const canonicalUrl = (
    `https://gofitify.com` + (router.asPath === '/' ? '' : router.asPath)
  ).split('?')[0]
  const { t } = useTranslation()

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_ID,
      })

      getPerformance(app)

      const analytics = getAnalytics()
      const url = new URL(window.location.href)
      const utmSource = url.searchParams.get('utm_source')
      const utmMedium = url.searchParams.get('utm_medium')
      const utmCampaign = url.searchParams.get('utm_campaign')
      const utmContent = url.searchParams.get('utm_content')
      const utmTerm = url.searchParams.get('utm_term')

      setUserProperties(analytics, {
        source: utmSource,
        medium: utmMedium,
        campaign: utmCampaign,
      })

      if (utmSource && utmMedium && utmCampaign) {
        logEvent('utm_conversion', {
          source: utmSource,
          medium: utmMedium,
          campaign: utmCampaign,
          content: utmContent,
          term: utmTerm,
        })
      }
    }
  }, [])

  const theme = {
    variant: ThemeVariants.DIGITAL,
    textVariants: textVariants,
    colors: themeColors,
  }

  return (
    <FitifyThemeProvider theme={theme}>
      <GlobalStyleProxy />
      <DefaultSeo
        {...SEO}
        title={t('page_title')}
        description={t('landing_page_subheadline')}
        canonical={canonicalUrl}
      />
      <SkeletonTheme
        baseColor={'#EEEEEE'}
        highlightColor={'#DFDFDF'}
        borderRadius="0.250rem"
        duration={1}
        enableAnimation
      >
        <div id={ELEMENT_IDS.home}>
          <Component {...pageProps} />
        </div>
      </SkeletonTheme>
    </FitifyThemeProvider>
  )
}

export default appWithTranslation(MyApp)
