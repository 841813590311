import { colors } from 'fitify-ui'
import {
  phone,
  biggerPhoneLandscape,
  tabletPortrait,
  xsPhone,
  biggerPhone,
} from 'fitify-ui-landing/src/theme/breakpoints'
import { css } from 'styled-components'

/**
 * TextTypesVariants
 */
declare global {
  enum TextTypesVariants {
    'header-title' = 'header-title',
    'header-title-hc' = 'header-title-hc',
    'condensed-title' = 'condensed-title',
    'footer-copy' = 'footer-copy',
    'header-paragraph' = 'header-paragraph',
    'animated-image-group-label' = 'animated-image-group-label',
    'animated-image-group-value' = 'animated-image-group-value',
    'faq-title' = 'faq-title',
    'how-it-works-jump' = 'how-it-works-jump',
    'modal-title' = 'modal-title',
    'newsletter-title' = 'newsletter-title',
    'special-offer' = 'special-offer',
    'trainer-description' = 'trainer-description',
    'tryit-headline' = 'tryit-headline',
    'tryit-paragraph' = 'tryit-paragraph',
    'try-it-warning' = 'try-it-warning',
    'how-it-works-content' = 'how-it-works-content',
    'feature-content' = 'feature-content',
    'business-title' = 'business-title',
    'apps-title' = 'apps-title',
    'review-content' = 'review-content',
    'error-title' = 'error-title',
    'error-description' = 'error-description',
    'error-paragraph' = 'error-paragraph',
    'feature-title' = 'feature-title',
    'dg-feature-title' = 'dg-feature-title',
    'tool-title' = 'tool-title',
    'tool-subheadline' = 'tool-subheadline',
    'promo-title' = 'promo-title',
    'promo-paragraph' = 'promo-paragraph',
    'achievement-title' = 'achievement-title',
    'reviews-job' = 'reviews-job',
    'work-statistics-title' = 'work-statistics-title',
    'work-statistics-percent' = 'work-statistics-percent',
    'work-statistics-note' = 'work-statistics-note',
    'achievement-date' = 'achievement-date',
  }
}

export const textVariants = {
  'header-title': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 56px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: ${colors.Blue900};

    ${biggerPhoneLandscape(css`
      font-size: 40px;
    `)};

    ${phone(css`
      font-size: 40px;
    `)};

    ${xsPhone(css`
      font-size: 32px;
    `)};
  `,
  'header-title-hc': css`
    font-family: 'Graphik Wide Web', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 56px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: ${colors.Black};

    ${biggerPhoneLandscape(css`
      font-size: 40px;
    `)};

    ${phone(css`
      font-size: 40px;
    `)};

    ${xsPhone(css`
      font-size: 32px;
    `)};
  `,
  'work-statistics-title': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 120%;
  `,
  'work-statistics-percent': css`
    font-family: 'Graphik Wide Web', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 56px;
    letter-spacing: -1px;
    line-height: 120%;
  `,
  'work-statistics-note': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  `,
  'condensed-title': css`
    font-family: 'Graphik XXCond Web', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 64px;
    line-height: 120%;
    text-transform: uppercase;

    ${biggerPhone(css`
      font-size: 56px;
      letter-spacing: -0.56px;
      margin-top: 24px;
    `)}
  `,
  'error-title': css`
    font-family: 'Graphik Wide Web', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 88px;
  `,
  'error-description': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-weight: 500;
    font-size: 32px;
  `,
  'error-paragraph': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
  `,
  'how-it-works-jump': css`
    font-family: 'Graphik XXCond Web', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 96px;
    line-height: 90%;
    text-transform: uppercase;
  `,
  'how-it-works-content': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: ${colors.Gray700};
  `,
  'reviews-job': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: ${colors.Gray700};
  `,
  'feature-content': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: ${colors.Gray700};
  `,
  'modal-title': css`
    font-family: 'Graphik Wide Web', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 110%;
    letter-spacing: -0.02em;

    ${phone(css`
      font-size: 32px;
    `)};
  `,
  'header-paragraph': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;

    ${biggerPhoneLandscape(css`
      font-size: 16px;
    `)};

    ${phone(css`
      font-size: 16px;
    `)};
  `,
  'tool-title': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;

    ${phone(css`
      font-size: 32px;
    `)};
  `,
  'tool-subheadline': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: ${colors.Gray800};

    ${biggerPhoneLandscape(css`
      font-size: 16px;
    `)};
  `,
  'tryit-headline': css`
    font-family: 'Graphik Wide Web', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 56px;
    line-height: 120%;
    letter-spacing: -0.02em;

    ${tabletPortrait(css`
      font-size: 48px;
    `)};
  `,
  'tryit-paragraph': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: ${colors.Gray800};
  `,
  'footer-copy': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: ${colors.Gray600};
  `,
  'faq-title': css`
    font-family: 'Graphik Wide Web', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 120%;
    text-transform: capitalize;
  `,
  'newsletter-title': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
  `,
  'try-it-warning': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 110%;
    text-transform: uppercase;
  `,
  'trainer-description': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
  `,
  'business-title': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: ${colors.Blue900};

    ${biggerPhone(css`
      font-size: 40px;
      margin-top: 24px;
    `)};
    ${phone(css`
      font-size: 32px;
    `)};
  `,
  'review-content': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: ${colors.Gray800};
  `,
  'apps-title': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: ${colors.Blue900};

    ${tabletPortrait(css`
      letter-spacing: -0.05em;
    `)};
    ${biggerPhone(css`
      font-size: 40px;
    `)};
    ${phone(css`
      font-size: 32px;
    `)};
  `,
  'animated-image-group-label': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
  `,
  'animated-image-group-value': css`
    font-family: 'Graphik Wide Web', sans-serif;
    font-weight: 500;
    font-size: 32px;
  `,
  'special-offer': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-style: normal;
    font-size: 14px;
  `,
  'feature-title': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-weight: 500;
    font-size: 32px;
    line-height: 120%;

    ${phone(css`
      font-size: 24px;
    `)}
  `,
  'dg-feature-title': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
    color: ${colors.Blue900};

    ${phone(css`
      font-size: 24px;
    `)}
  `,
  'achievement-title': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-weight: 600;
    font-size: 54px;
    line-height: 120%;

    ${phone(css`
      font-size: 40px;
    `)}
  `,
  'achievement-date': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: ${colors.Gray600};

    ${phone(css`
      font-size: 20px;
    `)}
  `,
  'promo-title': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-weight: 800;
    font-size: 32px;
    color: ${colors.Blue900};
    line-height: 120%;
  `,
  'promo-paragraph': css`
    font-family: 'Graphik LCG Web', sans-serif;
    font-size: 16px;
    color: ${colors.Gray500};
    line-height: 22px;
  `,
}
